<template>
  <v-app id="app">
    <v-navigation-drawer
      v-model="drawer"
      app
      v-if="isAuthenticated"
      :clipped="$vuetify.breakpoint.lgAndUp"
    >
      <v-list dense>
        <v-list-item
          selectable
          v-for="(item, index) in items"
          :key="index"
          :to="item.to"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="red darken-4" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <app-navigation />
    </v-app-bar>
    <v-main transition="slide-x-transition">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AppNavigation from '@/components/AppNavigation'
import { mapGetters } from "vuex";

export default {
  name: 'AdminLayout',
  components: {
    AppNavigation,
  },
  data() {
    return {
      adminItems: [
        { icon: "mdi-view-grid", title: "Dashboard", to: "/admin/dashboard" },
        { icon: "mdi-home-outline", title: "Campus Management Report", to: "/admin/report-campus-management" }, 
        { icon: "mdi-school-outline", title: "Student Management Report", to: "/admin/report-student-management" },
        { icon: "mdi-teach", title: "Teacher Management Report", to: "/admin/report-teacher-management" }, 
        { icon: "mdi-account", title: "User Management", to: "/admin/user-management" },
        { icon: "mdi-certificate", title: "Award Management", to: "/admin/award-management" },
        { icon: "mdi-fountain-pen-tip", title: "Homework Marking", to: "/admin/homework" }, 
      ],
      stateDirectorItems: [
        { icon: "mdi-view-grid", title: "Dashboard", to: "/state/dashboard" },
        { icon: "mdi-home-outline", title: "Campus Management Report", to: "/state/report-campus-management" }, 
        { icon: "mdi-school-outline", title: "Student Management Report", to: "/state/report-student-management" },
        { icon: "mdi-teach", title: "Teacher Management Report", to: "/state/report-teacher-management" }, 
        { icon: "mdi-account", title: "User Management", to: "/state/user-management" },
        { icon: "mdi-certificate", title: "Award Management", to: "/state/award-management" },   
        { icon: "mdi-fountain-pen-tip", title: "Homework Marking", to: "/state/homework" }, 
      ],
      campusDirectorItems: [
        { icon: "mdi-view-grid", title: "Dashboard", to: "/campus/dashboard" },
        { icon: "mdi-home-outline", title: "Campus Management Report", to: "/campus/report-campus-management" }, 
        { icon: "mdi-school-outline", title: "Student Management Report", to: "/campus/report-student-management" },
        { icon: "mdi-teach", title: "Teacher Management Report", to: "/campus/report-teacher-management" }, 
        { icon: "mdi-account", title: "User Management", to: "/campus/user-management" },
        { icon: "mdi-certificate", title: "Award Management", to: "/campus/award-management" },    
        { icon: "mdi-fountain-pen-tip", title: "Homework Marking", to: "/campus/homework" }, 
      ],
      drawer: null,
    };
  },
  computed: {
    items() {
      if (this.isAdmin) return this.adminItems;
      else if (this.isStateDirector) return this.stateDirectorItems;
      else if (this.isCampusDirector) return this.campusDirectorItems;
      return [];
    },
    ...mapGetters(["isAuthenticated", "isAdmin", "isStateDirector", "isCampusDirector"])
  },
}
</script>

<style>
</style>
